import {
	SEARCH_REQUEST,
	SEARCH_SUCCESS,
	SEARCH_FAIL,
	CLEAN_SEARCH,
} from '../constants/searchConstants';

export const searchReducer = (
	state = { loading: false, result: { result_header: {}, records: [] } },
	action
) => {
	switch (action.type) {
		case SEARCH_REQUEST: {
			return { ...state, loading: true };
		}

		case SEARCH_SUCCESS: {
			return { ...state, loading: false, result: action.payload };
		}

		case SEARCH_FAIL: {
			return { ...state, loading: false, error: action.error };
		}

		case CLEAN_SEARCH: {
			return { result: { result_header: {}, records: [] } };
		}

		default:
			return state;
	}
};
