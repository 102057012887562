import {
	USER_SIGNIN_FAIL,
	USER_SIGNIN_REQUEST,
	USER_SIGNIN_SUCCESS,
	USER_SIGNOUT,
	USER_REFRESH_REQUEST,
	USER_REFRESH_SUCCESS,
	USER_REFRESH_FAIL,
} from '../constants/userConstants';

export const userSigninReducer = (
	state = { loading: false, userInfo: {} },
	action
) => {
	switch (action.type) {
		case USER_REFRESH_REQUEST:
		case USER_SIGNIN_REQUEST: {
			return { ...state, loading: true };
		}
		case USER_REFRESH_SUCCESS:
		case USER_SIGNIN_SUCCESS: {
			return { ...state, loading: false, userInfo: action.payload };
		}
		case USER_REFRESH_FAIL:
		case USER_SIGNIN_FAIL: {
			return { ...state, loading: false, error: action.payload };
		}

		case USER_SIGNOUT: {
			return {};
		}
		default:
			return state;
	}
};
