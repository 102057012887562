import React from 'react';

export default function LoadingBox(){
    return (
        <div className="row bg-white mx-lg-1 p-2 shadow-sm rounded my-3">
            <div className="col-12 p-3 p-lg-4 bg-white text-center">
                <i className='fa fa-spinner fa-spin'></i> Cargando
            </div>
        </div>
    );
}