import React from "react";
import Footer from "../../components/Footer/Footer";

import Navbar from "../../components/Navbar/Navbar";

const BaseLayout = (props) => {
  return (
    <div className="d-flex flex-column justify-content-between">
      <Navbar />
      <main id="wrapper">{props.children}</main>
      <Footer />
    </div>
  );
};

export default BaseLayout;
